import React, { Fragment, useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  UncontrolledTooltip,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import List from "../../wasfaty/components/listing/List";
import {
  ClusterService,
  RegionService,
  IntlService,
} from "../../wasfaty/services";
import { getUserData } from "@utils";
import TotalRecords from "./components/TotalRecords";
import AddIcon from "../../../assets/wasfaty/addFormsvg.svg";
import SearchComponent from "./SearchComponent";
import AddNotificationModal from "../Add/Notifications/AddNotificationModal";
import NotificationService from "../../wasfaty/services/NotificationService";
import { getRoleLabel } from "../../wasfaty/services/AuthService";
import { Eye } from "react-feather";
import { ViewIcon } from "../../../assets/wasfaty/SVG";
function NotificationList() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [items, setItems] = useState([]);
  const [pagination, setPagination] = useState({
    page: 0,
    pageCount: 0,
    perPage: 10,
    to: 0,
    totalPages: 0,
  });
  const [loading, setLoading] = useState(false);
  const searchRef = useRef();
  const [content, setContent] = useState(null);
  useEffect(() => {
    getAllItems();
  }, []);
  const getAllItems = (query = { isFromSearch: false }) => {
    setLoading(true);
    const params = {
      page: (query?.selected || 0) + 1,
      per_page: query?.perPage || 10,
      search: searchRef?.current?.searchText || "",
    };
    if (query.isFromSearch) {
      searchRef?.current?.setSearchLoading?.(true);
    }
    NotificationService.find(params).then((res) => {
      if (res?.data?.data) {
        setItems(res.data.data.data);
        const _pagination = res.data.data;
        setPagination({
          page: _pagination.current_page,
          pageCount: Math.ceil(_pagination.total / _pagination.per_page),
          perPage: _pagination.per_page,
          totalPages: _pagination.total,
          to: _pagination.to,
        });
      }
      searchRef?.current?.setSearchLoading?.(false);
      setLoading(false);
    });
  };
  const handleSaveNotification = (newNotification) => {
    setLoading(true);
    NotificationService.create(newNotification)
      .then((response) => {
        if (response?.data) {
          console.log("Notification saved successfully", response.data);
          getAllItems();
        }
      })
      .catch((error) => {
        console.error("Error saving notification:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const basicColumnsObligation = [
    {
      name: "Subject",
      sortable: false,
      cell: (row) => <span>{row.subject || "-"}</span>,
    },
    {
      name: "To Roles",
      sortable: false,
      cell: (row) => (
        <span>
          {row?.user_roles?.map?.((role) => getRoleLabel(role))?.join?.(", ") ||
            "-"}
        </span>
      ),
    },
    {
      name: "To Emails",
      sortable: false,
      cell: (row) => <span>{row?.user_emails?.join?.(", ") || "-"}</span>,
    },
    {
      name: "Send By",
      sortable: false,
      cell: (row) => <span>{row?.user?.name || "-"}</span>,
    },
    {
      name: "Body",
      sortable: false,
      cell: (row) => (
        <div
          onClick={() => setContent(row.content)}
          style={{ cursor: "pointer" }}
          className="flex items-center justify-center"
        >
          {ViewIcon}
        </div>
      ),
    },
  ];
  return (
    <Fragment>
      <Card className="shadow-none m-0 mb-1">
        <CardBody style={{ padding: 20 }}>
          <Row>
            <TotalRecords
              md="2"
              flex=""
              title={"Notification List"}
              number={pagination?.totalPages}
            />
            <Col md="7" className="dropdown-container d-flex flex-wrap"></Col>
            <Col md="3" sm="12">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  marginBottom: 5,
                  alignItems: "center",
                }}
              >
                <div
                  style={{ marginLeft: 10 }}
                  className="cursor-pointer"
                  onClick={() => setIsModalOpen(true)}
                  id="add"
                >
                  <img src={AddIcon} alt="AddIcon" />
                </div>
                <UncontrolledTooltip placement="top" target="add">
                  {IntlService.m("Add New")}
                </UncontrolledTooltip>
                {/* <div id="search">
                  <SearchComponent
                    onSearchEnd={getAllItems}
                    ref={searchRef}
                    isVisiable={true}
                  />
                  <UncontrolledTooltip placement="top" target="search">
                    {IntlService.m("Search")}
                  </UncontrolledTooltip>
                </div> */}
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <List
        basicColumns={basicColumnsObligation}
        Mock={items}
        isLoading={loading}
        pagination={pagination}
        handlePageChange={getAllItems}
      />
      <AddNotificationModal
        isOpen={isModalOpen}
        toggle={() => setIsModalOpen(!isModalOpen)}
        onSave={handleSaveNotification}
      />
      <JournalModal
        content={content}
        modal={true}
        toggle={() => setContent(null)}
      />
    </Fragment>
  );
}
export default NotificationList;
const JournalModal = ({ content, toggle }) => {
  const toggleClose = () => toggle();
  return (
    <Fragment>
      <Modal
        centered
        isOpen={!!content}
        toggle={toggleClose}
        unmountOnClose={true}
        style={{ overflow: "hidden" }}
      >
        <ModalHeader toggle={toggleClose}>{"Body"}</ModalHeader>
        <ModalBody>
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              display: "block",
            }}
            dangerouslySetInnerHTML={{
              __html: content || "N/A",
            }}
          />
        </ModalBody>
      </Modal>
    </Fragment>
  );
};