import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from "reactstrap";
import Select from "react-select";
import "./style.scss";
import { selectThemeColors } from "@utils";
import {
  AuthService,
  IntlService,
  UserService,
} from "../../../wasfaty/services";
import FroalaEditor from "../../../DynamicForm/components/Froala";
import { UserPresistModal } from "../../../wasfaty/Models/UserPresistModal";
import { observer } from "mobx-react";
import { UserSearchModal } from "../../../wasfaty/Models/UserSearchModal";

const AddNotificationModal = ({ isOpen, toggle, onSave }) => {
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [showUserSelector, setShowUserSelector] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState(null);
  const [userModal] = useState(() => new UserSearchModal());

  const [forceRtl, setForceRtl] = useState(IntlService.isRtl);

  const [errors, setErrors] = useState({
    subject: "",
    roles: "",
    users: "",
    body: "",
  });

  const roles = AuthService.roles;

  useEffect(() => {
    if (isOpen) {
      resetForm();
    }
  }, [isOpen]);

  const validateForm = () => {
    let isValid = true;
    const newErrors = { subject: "", roles: "", users: "", body: "" };

    if (!subject.trim()) {
      newErrors.subject = IntlService.m("Subject is required");
      isValid = false;
    }

    if (!showUserSelector && selectedRoles.length === 0) {
      newErrors.roles = IntlService.m("Please select at least one role or choose individuals");
      isValid = false;
    }

    if (showUserSelector && (!selectedUsers || selectedUsers.length === 0)) {
      newErrors.users = IntlService.m("Please select at least one user");
      isValid = false;
    }

    if (!body.trim()) {
      newErrors.body = IntlService.m("Body is required");
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSave = () => {
    if (validateForm()) {
      onSave({
        user_roles: selectedRoles.map((role) => role.value),
        user_emails: selectedUsers?.map((user) => user.email) || null,
        subject,
        content: body,
        is_individual: showUserSelector,
      });
      resetForm();
      toggle();
    }
  };

  const resetForm = () => {
    setSelectedRoles([]);
    setSelectedUsers(null);
    setShowUserSelector(false);
    setSubject("");
    setBody("");
    setErrors({ subject: "", roles: "", users: "", body: "" });
  };

  const handleCheckboxChange = (e) => {
    setShowUserSelector(e.target.checked);
    if (e.target.checked) {
      setSelectedRoles([]);
      setErrors((prev) => ({ ...prev, roles: "", users: "" }));
    } else {
      setSelectedUsers(null);
      setErrors((prev) => ({ ...prev, roles: "", users: "" }));
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
      className="custom-notification-modal"
    >
      <ModalHeader toggle={toggle}>
        {IntlService.m("Add New Notification")}
      </ModalHeader>
      <ModalBody>
        <Form>
        <div className="mb-1">
            <Label check className="small">
              <Input
                type="checkbox"
                checked={showUserSelector}
                onChange={handleCheckboxChange}
                className="me-2"
              />{" "}
              {IntlService.m("Individuals")}
            </Label>
           
          </div>
          {!showUserSelector && (
            <div className="mb-1">
              <Label for="roles">{IntlService.m("Roles")}</Label>
              <Select
                id="roles"
                options={roles}
                isMulti
                theme={selectThemeColors}
                className={`react-select form-control p-0 ${errors.roles ? 'is-invalid' : ''}`}
                classNamePrefix="select"
                placeholder="Select Roles"
                value={selectedRoles}
                onChange={(value) => {
                  setSelectedRoles(value);
                  setErrors((prev) => ({ ...prev, roles: "" }));
                }}
              />
              {errors.roles && <FormFeedback>{errors.roles}</FormFeedback>}
            </div>
          )}
          
          {showUserSelector && (
            <div className="mb-1">
              <Label for="users">{IntlService.m("Users")}</Label>
              <Select
                isMulti
                closeMenuOnSelect={false}
                placeholder={IntlService.m(
                  userModal.isLoading ? "Loading..." : "Select"
                )}
                onChange={(value) => {
                  setSelectedUsers(value);
                  setErrors((prev) => ({ ...prev, users: "" }));
                }}
                value={selectedUsers}
                theme={selectThemeColors}
                className={`react-select ${errors.users ? 'is-invalid' : ''}`}
                classNamePrefix="select"
                options={userModal.users}
                isLoading={userModal.isLoading}
                onInputChange={userModal.onSearch}
                menuPlacement="bottom"
                styles={{ zIndex: 1000, position: "absolute" }}
              />
              {errors.users && <div className="invalid-feedback d-block">{errors.users}</div>}
            </div>
          )}
          <div className="mb-1">
            <Label for="subject">{IntlService.m("Subject")}</Label>
            <Input
              type="text"
              name="subject"
              id="subject"
              placeholder="Enter subject"
              value={subject}
              onChange={(e) => {
                setSubject(e.target.value);
                setErrors((prev) => ({ ...prev, subject: "" }));
              }}
              className={`form-control ${errors.subject ? 'is-invalid' : ''}`}
            />
            {errors.subject && <FormFeedback>{errors.subject}</FormFeedback>}
          </div>
          <div className="mb-2 froala-editor-wrapper">
            <Label for="body">{IntlService.m("Body")}</Label>
            <FroalaEditor
              setValue={(newBody) => {
                setBody(newBody);
                setErrors((prev) => ({ ...prev, body: "" }));
              }}
              value={body}
            />
            {errors.body && <div className="invalid-feedback d-block">{errors.body}</div>}
          </div>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" size="sm" onClick={handleSave}>
          Save
        </Button>{" "}
        <Button color="secondary" size="sm" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default observer(AddNotificationModal);

