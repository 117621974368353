import { useEffect, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  Button,
  Spinner,
} from "reactstrap";
import { FormattedMessage } from "react-intl";
import { Radio } from "antd";

const XLSX = require("xlsx");

const SyncStandards = () => {
  const [events, setEvents] = useState([]);
  const [savedEventSource, setEventSource] = useState();
  const [modal, setModal] = useState(false);
  const [formModal, setFormModal] = useState(false);
  const [isStarted, setIsStarted] = useState(false);
  const [selectedForm, setSelectedForm] = useState();
  const [optionFilter, setOptionFilter] = useState("All");

  const [isCompleted, setIsCompleted] = useState(false);
  useEffect(() => {
    // const eventSource = new EventSource(
    //   process.env.REACT_APP_BASEURL + "RiskCompliance/standardsUpdate"
    // );
  }, []);

  const toggleModal = (check, data) => {
    setSelectedForm(data);
    setModal(check);
    setOptionFilter("All");
  };

  const openFormModal = () => {
    let token = JSON.parse(localStorage.getItem("accessToken"));
    let eventSource = new EventSource(
      process.env.REACT_APP_BASEURL + `events?token=${token}`
    );

    // Handle messages received from the server
    eventSource.onmessage = function (event) {
      const newEvent = JSON.parse(event.data);

      if (newEvent.complete) {
        eventSource.close();
        setIsCompleted(true); // Close the stream when the server sends a "completion" event
      } else {
        setEvents((prevEvents) => [...prevEvents, newEvent]);
      }
    };

    // Handle the "completion" event from the server
    eventSource.addEventListener("completion", function (event) {
      eventSource.close(); // Close the stream when the server sends a "completion" event
    });

    // Handle connection errors
    eventSource.onerror = function (event) {
      if (eventSource.readyState === EventSource.CONNECTING) {
        console.log("Reconnecting...", eventSource.readyState); // Should be 0 (CONNECTING)
      } else if (eventSource.readyState === EventSource.CLOSED) {
        console.log("Connection closed.", eventSource.readyState); // Should be 2 (CLOSED)
      }

      setIsCompleted(true);
      eventSource.close(); // Close on error or when server disconnects
    };

    setEventSource(eventSource);
    setIsStarted(true);
  };

  const closeFormModal = () => {
    if (savedEventSource) {
      savedEventSource.close();
    }
    setEventSource();
    setEvents([]);
    setFormModal(false);
    setIsCompleted(false);
    setIsStarted(false);
  };

  const onChangeFilter = (e) => {
    setOptionFilter(e.target.value);
  };

  const getTotals = (form) => {
    let updated = 0;
    let notUpdated = 0;
    Object.entries(form?.standards || {}).forEach(([step, questions]) => {
      questions.forEach((question) => {
        if (question.isUpdated) {
          updated++;
        } else {
          notUpdated++;
        }
      });
    });

    return {
      updated,
      notUpdated,
      all: updated + notUpdated,
    };
  };

  return (
    <>
      <div>
        <Button.Ripple
          outline
          onClick={() => setFormModal(true)}
          className="add-form-button waves-effect round btn btn-primary my-1"
        >
          <p>
            <FormattedMessage
              defaultMessage={"Sync Standards"}
              id={"Sync Standards"}
            />
          </p>
        </Button.Ripple>
      </div>
      <div>
        <Modal
          isOpen={modal}
          toggle={() => toggleModal()}
          style={{ "--modal-max-width": "70vw" }}
        >
          {isStarted && (
            <>
              <Radio.Group
                onChange={onChangeFilter}
                defaultValue={optionFilter}
                style={{ padding: 20 }}
              >
                <Radio.Button value="All">All</Radio.Button>
                <Radio.Button value="Updated">Updated</Radio.Button>
                <Radio.Button value="Not Updated">Not Updated</Radio.Button>
              </Radio.Group>
              <h1></h1>
              <ModalHeader>{selectedForm?.form?.name}</ModalHeader>
              <ModalBody className="d-flex" style={{ flexDirection: "column" }}>
                {Object.entries(selectedForm?.standards || {})?.map?.(
                  ([step, questions]) => {
                    let filterQuestions = questions.filter((question) => {
                      if (optionFilter == "Updated" && !question.isUpdated)
                        return null;
                      if (optionFilter == "Not Updated" && question.isUpdated)
                        return null;

                      return true;
                    });

                    if (!filterQuestions.length) return null;
                    return (
                      <>
                        <h4>{step}</h4>
                        <Table bordered hover responsive>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Identifier</th>
                              <th>Standard</th>
                              <th>Updated</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filterQuestions.map?.((question, index) => {
                              return (
                                <tr>
                                  <th scope="row">{index + 1}</th>
                                  <td>{question.identifier || "N/A"}</td>
                                  <td>{question.title}</td>
                                  <td>
                                    {question.isUpdated ? (
                                      <h5 style={{ color: "green" }}>Yes</h5>
                                    ) : (
                                      <h5 style={{ color: "red" }}>No</h5>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </>
                    );
                  }
                )}
              </ModalBody>
              <ModalFooter></ModalFooter>
            </>
          )}
        </Modal>

        <Modal isOpen={formModal} style={{ "--modal-max-width": "50vw" }}>
          <ModalHeader toggle={() => closeFormModal()}>
            <h5>All Forms</h5>
            {events?.length > 0 && isStarted && (
              <p
                onClick={() => new ExcelServiceStandards(events)}
                style={{ cursor: "pointer" }}
              >
                Download as excel
              </p>
            )}
          </ModalHeader>
          <ModalBody className="d-flex" style={{ flexDirection: "column" }}>
            {!isStarted && (
              <>
                <i>
                  Note: Updating all forms with standards may take some time as
                  this is a long-running task.
                </i>
                <Button.Ripple
                  outline
                  onClick={() => openFormModal()}
                  className="add-form-button waves-effect round btn btn-primary my-1"
                >
                  <p>
                    <FormattedMessage
                      defaultMessage={"Start Syncing"}
                      id={"Start Syncing"}
                    />
                  </p>
                </Button.Ripple>
              </>
            )}
            {isStarted && (
              <div>
                {events?.length > 0 && (
                  <Table bordered hover responsive>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {events.map((event, index) => {
                        let states = getTotals(event);
                        return (
                          <tr
                            onClick={() => {
                              if (event?.standards) {
                                toggleModal(true, event);
                              }
                            }}
                          >
                            <th scope="row">{index + 1}</th>

                            {!!event?.form?.name && (
                              <td key={index}>{event.form.name}</td>
                            )}
                            <td>
                              {event?.standards ? (
                                <>
                                  <p>Detail</p>

                                  <p style={{ color: "green" }}>
                                    {states.updated} |{" "}
                                    <span style={{ color: "red" }}>
                                      {states.notUpdated}
                                    </span>
                                  </p>
                                </>
                              ) : (
                                <p style={{ color: "red" }}>
                                  No Standard Found
                                </p>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                )}

                {!isCompleted && events?.length > 0 && (
                  <>
                    Please wait forms updating...
                    <Spinner size={"sm"} />
                  </>
                )}
                {!events?.length && !isCompleted && (
                  <>
                    Connecting to server...
                    <Spinner size={"sm"} />
                  </>
                )}
                {isCompleted && (
                  <h4 style={{ color: "green" }}>
                    Forms updated successfully!
                  </h4>
                )}
              </div>
            )}
          </ModalBody>
          <ModalFooter></ModalFooter>
        </Modal>
      </div>
    </>
  );
};

export default SyncStandards;

class ExcelServiceStandards {
  worksheets = [];
  forms = [];
  constructor(forms) {
    this.forms = forms;

    this.worksheets = this.forms
      .filter(
        (f) => f.standards && Object.entries(f?.standards || {}).length > 0
      )
      .map(({ form, standards }, index) => {
        let sheet = {};
        sheet.name = form.name;
        sheet.data = Object.entries(standards)
          .map(([step, value]) => {
            if (value?.length) {
              return value.map((std, index) => {
                return {
                  No: index + 1,
                  "Step Name": step,
                  Standard: std.title,
                  "Found and updated": std.isUpdated ? "Yes" : "No",
                };
              });
            }
          })
          .filter((i) => i)
          .flat();
        return sheet;
      });

    this.generateExcel();
  }

  generateExcel() {
    if (this.worksheets?.length) {
      const workbook = XLSX.utils.book_new();
      const sheetNamesSet = new Set(); // To track unique sheet names

      this.worksheets.forEach((sheet) => {
        // Sanitize the sheet name by removing invalid characters
        sheet.name = sheet.name.replace(/[:\/\\?\*\[\]]/g, "");

        // Prepare the data with the full name of the form in the first row
        const fullSheetData = [
          { FullName: `Name of Form: ${sheet.name}` },
          ...sheet.data,
        ];

        const worksheet = XLSX.utils.json_to_sheet(fullSheetData);

        // Truncate the sheet name to 31 characters
        let sheetName =
          sheet.name.length > 30 ? sheet.name.substring(0, 28) : sheet.name;

        // Ensure the sheet name is unique by adding a numeric suffix if necessary
        let originalSheetName = sheetName;
        let counter = 1;
        while (sheetNamesSet.has(sheetName)) {
          // Append a counter to make the sheet name unique
          sheetName = `${originalSheetName}_${counter}`;
          counter++;
        }

        // Add the final (unique) sheet name to the set
        sheetNamesSet.add(sheetName);

        // Append the worksheet to the workbook with the unique sheet name
        XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
      });

      // Write the Excel file
      XLSX.writeFile(workbook, `Forms Standards info.${"xlsx"}`);
    }
  }
}
