import { autorun, makeAutoObservable } from "mobx";
import FileUploadModal from "./FileUploadModel";
import { SC } from "../api/serverCall";
import toast from "react-hot-toast";
import { RegionService } from "../../wasfaty/services";

export const formFields = {};

export class FormBuilderModel {
  //crete
  formName = null;
  icon = new FileUploadModal();
  modalShow = false;
  meta_data = {};
  regionOptions = [];
  isDotnet=true
  createValidation = false;
  isEdit = false;
  id = "";
  isUpdating = false;

  is_campaign = false;
  is_support = false;
  campaign_title = "";
  start_date = "";
  end_date = "";

  constructor(props, task) {
    this.getRegionOptions();
    makeAutoObservable(this);
  }

  getRegionOptions = () => {
    RegionService.getByMethod("Pharmacy").then((res) => {
      this.regionOptions = res.map((region) => {
        return {
          // ...region,
          label: region.name,
          value: region._id,
        };
      });
    });
  };

  toggleModel = () => {
    this.modalShow = !this.modalShow;
    this.icon = new FileUploadModal();
  };

  validateCreate = () => {
    let check = false;
    check = !!this.formName && !!this.icon.images.length;

    if (this.is_campaign) {
      check = check && this.start_date && this.end_date && this.campaign_title;
    }

    this.createValidation = !check;
    return check;
  };

  getCreatePayload = () => {
    let payload = {};
    payload.formName = this.formName;
    payload.icon = this.icon;
    return payload;
  };

  submitCreate = (setCreateModal, callback) => {
    this.isUpdating = true;
    let postData = {
      name: this.formName,
      icon: this.icon.images[0],
      meta_data: this.meta_data,
      id: this.id,
      is_campaign: this.is_campaign,
      is_support: this.is_support,
      campaign_title: this.campaign_title,
      start_date: this.start_date?.toISOString?.(),
      end_date: this.end_date?.toISOString?.(),
    };
    SC.postCall({
      url: this.isEdit ? "form/UpdateForm" : "form/create",
      data: postData,isDotNet:this.isDotnet
    }).then((res) => {
      if (res.status == 200) {
        callback();
        this.toggleModel();
        setTimeout(() => {
          toast.success("Form Created successfully!");
        }, 1000);
      }
      this.isUpdating = false;
    });
  };

  submitTemplate = (callback) => {
    this.isUpdating = true;

    let postData = {
      name: this.formName,
      is_template: true,
    };
    SC.postCall({
      url: "form/create",
      data: postData,
    }).then((res) => {
      if (res.status == 200) {
        callback();
        setTimeout(() => {
          toast.success("Form Created successfully!");
        }, 1000);
      }
      this.isUpdating = false;
    });
  };

  onEdit = (row) => {
    this.id = row._id;
    this.isEdit = true;
    this.formName = row.name;
    this.is_campaign = row.is_campaign;
    this.is_support = row.is_support;
    this.campaign_title = row.campaign_title;
    if (row.start_date) {
      this.start_date = new Date(row.start_date);
    }

    if (row.end_date) {
      this.end_date = new Date(row.end_date);
    }
    this.meta_data = !Array.isArray(row.meta_data) ? row.meta_data : {};
    this.modalShow = true;
    this.icon.setImages([row.saved_icon]);
  };

  openEngine = (id) => {
    SC.getCall({ url: `form/builder/${id}` }).then((res) => {
      if (res.status == 200) {
        window.open(res.data.link);
      }
    });
  };

  handleChange = (key, value) => {
    this[key] = value;
    autorun(() => {});
  };

  handleChangeMeta = (key, value) => {
    this.meta_data[key] = value;
    autorun(() => {});
  };

  init = (props) => {};

  get dropDownKeys() {
    return [];
  }

  getPayload(isDraft) {
    let payload = {};

    return payload;
  }
}
