import { SC } from "./Api/serverCall";
import { IntlService, ModuleService } from "./services";
import toast from "react-hot-toast";

export class Resources {
  async create(data) {
    return SC.postCall({
      url:
        this.route +
        (this.disableDefault ? "" : this.routes?.create || "-create"),
      data,
      isDotNet: this.isDotNet,
    });
  }

  async find(params = {}) {
    return SC.getCall({
      url:
        this.route +
        (this.disableDefault
          ? ""
          : typeof this.routes?.find === "string"
          ? this.routes?.find
          : "-index"),
      params,
      isDotNet: this.isDotNet,
    });
  }

  async show(id) {
    return SC.getCall({
      url:
        this.route +
        (this.disableDefault ? "" : this.routes?.show || `-show`) +
        `/${id}`,
      isDotNet: this.isDotNet,
    });
  }

  async tasks(id, params) {
    return SC.getCall({
      url:
        this.route +
        (this.disableDefault ? "" : this.routes?.tasks || `-tasks`) +
        `/${id}`,
      params,
      isDotNet: this.isDotNet,
    });
  }

  async update(id, data) {
    return SC.putCall({
      url:
        this.route +
        (this.disableDefault ? "" : this.routes?.update || `-Update`) +
        `/${id}`,
      data,
      isDotNet: this.isDotNet,
    });
  }

  async delete(id, deleteReason = "") {
    return SC.deleteCall({
      url:
        this.route +
        (this.disableDefault ? "" : this.routes?.delete || `-Delete`) +
        `/${id}`,
      delete_reason: deleteReason,
      isDotNet: this.isDotNet,
    });
  }

  async export({ data }) {
    return SC.getCall({
      url: "generatePdf",
      params: {
        id: data._id,
        slug: ModuleService.currentFormSlug,
      },
      responseType: "blob",
    }).then((res) => {
      try {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(res.data);
        link.download = data.reportNumber + ModuleService.currentFormName;
        link.click();
      } catch (error) {
        toast.error(
          IntlService.m("There is a problem with your network connection!")
        );
      }
    });
  }
}

export const Singleton = (SingletonClass) => {
  if (!SingletonClass.instance) {
    SingletonClass.instance = new SingletonClass();
  }
  return SingletonClass.instance;
};
