import React from "react";
import {
  GoogleMap,
  LoadScript,
  OverlayView,
  Polygon,
} from "@react-google-maps/api";
import { Card, Col, Row } from "reactstrap";
import { Skeleton } from "primereact/skeleton";

const containerStyle = {
  height: "100%",
  borderRadius: "20px",
  overflow: "hidden",
};

const center = {
  lat: 23.8859, // Default center
  lng: 45.0792,
};

const mapOptions = {
  disableDefaultUI: true,
  keyboardShortcuts: false,
  fullscreenControl: false,
  mapTypeControl: false,
  streetViewControl: false,
};

const circleStyle = {
  backgroundColor: "#008556",
  borderRadius: "50%",
  width: "45px",
  height: "45px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  border: "2px solid white",
  color: "#FFF",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "24px",
};

// Accurate coordinates for Saudi Arabia boundary (simplified for demonstration)
const saudiArabiaCoords = [
  { lat: 31.5084, lng: 37.3041 },
  { lat: 29.0985, lng: 39.1821 },
  { lat: 29.3366, lng: 42.7791 },
  { lat: 25.1346, lng: 47.9827 },
  { lat: 22.0331, lng: 50.2829 },
  { lat: 16.3479, lng: 49.5825 },
  { lat: 17.4104, lng: 41.7722 },
  { lat: 20.1747, lng: 38.6263 },
  { lat: 24.0784, lng: 36.5634 },
  { lat: 29.1086, lng: 35.6371 },
  { lat: 31.5084, lng: 37.3041 },
];

const MapViewCard = ({ loading, regions }) => {
  return (
    <Card className="map_card">
      <LoadScript googleMapsApiKey="AIzaSyBbODegH7VECWmiZHYBTAqd4Bab5bp1bFo">
        {loading && !regions ? (
          <Row className="mb-2">
            <Col md="12">
              <Skeleton width="" height="29rem"></Skeleton>
            </Col>
          </Row>
        ) : (
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={5}
            options={mapOptions}
          >
            {/* Add the accurate polygon boundary */}
            {/* <Polygon
                  paths={saudiArabiaCoords}
                  options={{
                    strokeColor: "#008556",
                    strokeOpacity: 1,
                    strokeWeight: 2,
                    fillColor: "#008556",
                    fillOpacity: 0.2,
                  }}
                /> */}
            {/* Render regions */}
            {regions?.map((region, index) => (
              <OverlayView
                key={index}
                position={{ lat: region.latitude, lng: region.longitude }}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              >
                <div style={circleStyle}>{region.percentage}</div>
              </OverlayView>
            ))}
          </GoogleMap>
        )}
      </LoadScript>
    </Card>
  );
};

export default MapViewCard;
