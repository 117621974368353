import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import classnames from "classnames";
import { IntlService, ModuleService } from "../../../../views/wasfaty/services";
import { getBreadcrumbs } from "../../../../views/wasfaty/services/GetBreadcrumbs";
import startCase from "lodash/startCase";
import { ModuleData } from "../../../../views/wasfaty/Helper/utils";
function isValidMongoObjectId(str) {
  // A valid MongoDB ObjectId should be a 24-character hexadecimal string
  const ObjectIdRegExp = /^[0-9a-fA-F]{24}$/;
  return ObjectIdRegExp.test(str);
}
const Breadcrumbs = () => {
  const { pathname } = useLocation();
  let breadcrumbs = getBreadcrumbs(pathname);

  // Parse query parameters
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const moduleData = ModuleData(searchParams);

  const filteredBreadCrumbs = breadcrumbs
    .filter((item) => !["/", "true"].includes(item.name))
    .filter((item) => !isValidMongoObjectId(item.name));

  let formId = String(pathname)
    .split("/")
    .filter((i) => i)?.[0];
  let formName = ModuleService.getNameId(formId);

  return (
    <Breadcrumb className="nav-breadcrumbs">
      {filteredBreadCrumbs.map(({ name, route, clickable }, index) => {
        const isLastItem = index === filteredBreadCrumbs.length - 1;
        const isFirstItem = index === 0;
        let nameForm = isFirstItem
          ? formName || ModuleService.getNameBySlug(name) || startCase(name)
          : ModuleService.getNameBySlug(name) || startCase(name);
        const displayName = IntlService.m(nameForm);
        const linkRoute = isFirstItem
          ? moduleData.task
            ? moduleData.breadCrumb
            : route
          : route;

        return (
          <React.Fragment key={index}>
            {index !== 0 && <span className="breadcrumb-separator">/</span>}
            <BreadcrumbItem>
              {isLastItem ? (
                <span style={{ cursor: "default" }}>{displayName}</span>
              ) : (
                <Link
                  to={linkRoute}
                  className={classnames({
                    "active-route": location.pathname === route,
                  })}
                  onClick={(event) => {
                    if (!clickable) event.preventDefault();
                  }}
                >
                  {isFirstItem
                    ? moduleData.task
                      ? moduleData.breadCrumb
                      : displayName
                    : displayName}
                </Link>
              )}
            </BreadcrumbItem>
          </React.Fragment>
        );
      })}
    </Breadcrumb>
  );
};

export default Breadcrumbs;
