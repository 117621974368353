import React, { useState } from "react";
import { IntlService } from "../../wasfaty/services";
import { TabView, TabPanel } from "primereact/tabview";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import DynamicFormGraphs from "./DynamicFormGraphs";
import RegionalDashboard from "./RegionalDashboard/RegionalDashboard";
// import RiskRegister from "./RiskRegister";
// import ComplianceExecutive from "./ComplianceExecutive/ComplianceExecutive";
import "./style.scss";

const pressed = {
  0: true,
};
function ComplianceDashboard() {
  const [activeTab, setActiveTab] = useState(0);
  const [tabs] = useState([
    { label: "Compliance Dashboard" },
    // { label: "MOH Compliance Executive" },
  ]);

  return (
    <div>
      {/* <TabView
        scrollable
        className="my-tabview"
        activeIndex={activeTab}
        onTabChange={(e) => {
          pressed[e.index] = true;
          setActiveTab(e.index);
        }}
      >
        {tabs.map((tab, index) => (
          <TabPanel header={IntlService.m(tab.label)} key={index}></TabPanel>
        ))}
      </TabView> */}

      <div style={{ display: activeTab === 0 ? "block" : "none" }}>
        {pressed[0] && <RegionalDashboard />}
      </div>
      {/* <div style={{ display: activeTab === 1 ? "block" : "none" }}>
        {pressed[1] && <DynamicFormGraphs />}
      </div> */}

      {/* <DynamicFormGraphs /> */}
    </div>
  );
}

export default ComplianceDashboard;
