import { Singleton, Resources } from "../Resource";
import { SC } from "../Api/serverCall";
import startCase from "lodash/startCase";
import get from "lodash/get";
import moment from "moment";
class Service extends Resources {
  route = "CustomNotification";
  show = `CustomNotification/show`;
  routes = {
    find: "",
    create: "/create",
    show: "/show",
    update: "/update",
    delete: "/delete",
    export: "CustomNotification/export",
  };
  isDotNet=true

  constructor() {
    super(arguments);
  }

  getHeaders(obj) {
    if (obj) {
      return [
        { title: "Region", dataIndex: "region" },

        {
          title: startCase("name"),
          dataIndex: "name",
        },
      ];
    }

    return [];
  }

  getClustersByRegionID = async (regionId) => {
    try {
      let res = await SC.getCall({
        url: "get-form-clusters",
        params: { regionId },
      });
      let _data = get(res, "data.data");
      if (_data?.length) {
        return _data.map((item) => ({
          ...item,
          label: item.name,
          value: item._id,
        }));
      }

      return [];
    } catch (e) {
      return [];
    }
  };

  getClusterById(id) {
    return SC.getCall({
      url: this.show + `/${id}`,
    });
  }

  getMaxnumber(users, number) {
    if (users?.length > number) {
      return users.length;
    }

    return number;
  }

  mapExportData(data = []) {
    try {
      data = data.map((item) => {
        item.region = get(item, "region.name", "");
        item.name = get(item, "name", "");
        item.created_at = moment(item.created_at).format("DD-MM-YYYY h:mm A");

        return item;
      });
    } catch (error) {
      console.log("Nupco export error: ", error);
    }

    return {
      headers: this.getHeaders(data[0]),
      data,
    };
  }
}

export default Singleton(Service);
