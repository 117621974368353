import React from "react";
import { Card } from "reactstrap";

import { allLines } from "../RegionalDashboardHelper";
import { ArrowTopRightIcon } from "../../../../../assets/wasfaty/SVG";
import moment from "moment";
import { IntlService } from "../../../../wasfaty/services";

const Line = ({ angle, position, color }) => (
  <div
    style={{
      height: 15,
      width: 1.5,
      background: color,
      marginRight: 2,
      transform: `rotate(${angle}deg)`,
      position: "absolute",
      borderRadius: 100,
      ...position,
    }}
  />
);

const CirculerProgressBarCard = ({
  sitesSubmission,
  sectionName,
  selectedDate,
}) => {
  const circleLines = allLines.map((item, index) => {
    const greenLines = (262 * sitesSubmission?.currentMonthSubmission) / 100;
    if (greenLines > index) {
      item.color = "#008557";
    } else item.color = "#E2E4E9";
    return item;
  });

  const formatDateRange = (startDate, endDate, rangeType) => {
    const formatMap = {
      day: "YYYY-MM-DD",
      month: "YYYY-MM",
      year: "YYYY",
    };

    const format = formatMap[rangeType] || "YYYY-MM-DD";

    return `${moment(startDate).format(format)} to ${moment(endDate).format(
      format
    )}`;
  };

  const getDefaultDateRange = () => {
    return `${moment()
      .subtract(30, "days")
      .format("MM-DD")} to ${moment().format("MM-DD")}`;
  };

  const getDaysDiffText = (startDate, endDate, rangeType) => {
    const diffDays = moment(endDate).diff(moment(startDate), `${rangeType}s`);
    return `from last ${diffDays} ${
      diffDays > 1 ? `${rangeType}s` : rangeType
    }`;
  };

  return (
    <Card className="percentage_card">
      <div className="d-flex justify-content-between align-items-center">
        <div className="region_name">{IntlService.m(sectionName)}</div>
        <div>{ArrowTopRightIcon}</div>
      </div>
      <div className="hr_row" />
      <div
        style={{
          background: "#FFF",
          height: 143,
          width: "453px",
          paddingBlock: 20,
          display: "flex",
          position: "relative",
        }}
      >
        <div className="d-flex justify-content-between align-items-center circle_box">
          <div className="d-flex justify-content-between align-items-center left">
            <div className="inner_circle_box">
              <div className="percentage">
                {sitesSubmission?.currentMonthSubmission}%
              </div>
              <div className="sites">
                {selectedDate?.length
                  ? formatDateRange(
                      selectedDate[0],
                      selectedDate[1],
                      selectedDate[2]
                    )
                  : getDefaultDateRange()}
              </div>

              <div className="sites">
                {IntlService.m("out of")} {sitesSubmission?.totalSubmittedSites}{" "}
                {IntlService.m("sites")}
              </div>
            </div>
            <div className="compiled">{IntlService.m("Complied")}</div>
          </div>
          <div className="right">
            <div className="percentage">
              {sitesSubmission?.previousMonthSubmission}%
            </div>
            <div className="days">
              {selectedDate?.length
                ? getDaysDiffText(
                    selectedDate[0],
                    selectedDate[1],
                    selectedDate[2]
                  )
                : IntlService.m("from last 30 days")}
            </div>
          </div>
        </div>
        <div style={{ position: "absolute", left: 41, display: "flex" }}>
          {circleLines.map(
            ({ isInlineTop, color }, index) =>
              isInlineTop && (
                <div
                  key={index}
                  style={{
                    height: 15,
                    width: 2,
                    background: color,
                    marginRight: 2,
                    borderRadius: 100,
                  }}
                />
              )
          )}
        </div>

        <div
          style={{
            position: "absolute",
            left: 41,
            top: 116,
            display: "flex",
            direction: "rtl",
          }}
        >
          {circleLines.map(
            ({ isInlineBottom, color }, index) =>
              isInlineBottom && (
                <div
                  key={index}
                  style={{
                    height: 15,
                    width: 2,
                    background: color,
                    marginRight: 2,
                    borderRadius: 100,
                  }}
                />
              )
          )}
        </div>

        {circleLines.map(
          ({ angle, position, isInline, key, color }, index) =>
            isInline !== true && (
              <Line
                key={`${key}-${index}`}
                angle={angle}
                position={position}
                color={color}
              />
            )
        )}
      </div>
    </Card>
  );
};

export default CirculerProgressBarCard;
