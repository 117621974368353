import {
  Input,
  Spinner,
  Button,
  ButtonDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";
import { Plus } from "react-feather";
import { FormattedMessage } from "react-intl";
import { IntlService, ModuleService } from "../../../../views/wasfaty/services";
import { searchIcon } from "../../../../assets/wasfaty/SVG";
import { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { debounce } from "lodash";
import classNames from "classnames";
import RoutingService from "../../../../views/wasfaty/services/RoutingService";
import { useNavigate } from "react-router-dom";
import AuthUser from "../../../../views/wasfaty/services/AuthService";

const QuickAddDropdown = ({ windowWidth }) => {
  const AllFormsList = RoutingService.getAllForms();
  const navigate = useNavigate();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchedItems, setSearchedItems] = useState(AllFormsList);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  // Debounce the search function
  const debouncedSearch = debounce((value) => {
    if (value === "") {
      setSearchedItems(AllFormsList);
    } else
      setSearchedItems(
        AllFormsList.filter((item) => {
          return ModuleService.getNameBySlug(item.slug)
            ?.toLowerCase()
            ?.includes(value.toLowerCase());
        })
      );
  }, 500); // Adjust the debounce delay as needed

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
  };
  useEffect(() => {
    // Call the debounced search function whenever the searchTerm changes
    debouncedSearch(searchTerm);

    // Clean up the debounce function on component unmount
    return () => {
      debouncedSearch.cancel();
    };
  }, [searchTerm]);

  if (!AuthUser.canCreate) {
    return null;
  }

  return (
    <ButtonDropdown
      isOpen={dropdownOpen}
      toggle={toggleDropdown}
      className={`quick-add-dropdown`}
    >
      <DropdownToggle color="white" className="m-0 p-0">
        <Button.Ripple
          outline
          onClick={() => {}}
          className={classNames(
            "add-form-button waves-effect round btun   btn btn-primary my-1",
            {
              "add-form-button-small m-1": windowWidth <= 1200,
            }
          )}
          style={{ height: 45 }}
        >
          {windowWidth > 1200 && (
            <p className="">
              <FormattedMessage
                defaultMessage={"Quick Create"}
                id={"Quick Create"}
              />
            </p>
          )}
          <Plus size={18} />
        </Button.Ripple>
      </DropdownToggle>
      <DropdownMenu
        className={classNames({
          "dropdown-menu-small": windowWidth <= 1200,
        })}
      >
        <div className="dropdown-menu-search mt-1">
          <Input
            value={searchTerm}
            placeholder={IntlService.m("Search")}
            onChange={handleSearch}
          />
          <figure>{false ? <Spinner size="sm" /> : searchIcon}</figure>
        </div>
        <PerfectScrollbar
          options={{ wheelPropagation: false }}
          style={{
            height: "300px",
            // overflowY: "scroll",
            marginTop: 5,
          }}
        >
          {searchedItems.map((item) => {
            const Icon =
              item.icon ||
              (() => {
                return null;
              });
            return (
              <DropdownItem
                className="w-100"
                onClick={() => navigate(item.navLink + "/Add")}
              >
                <div className="list-item">
                  <span>
                    <Icon />
                  </span>
                  <span className="list-title">
                    {IntlService.m(
                      ModuleService.getNameBySlug(item.slug) || item.title
                    )}
                  </span>
                </div>
              </DropdownItem>
            );
          })}
        </PerfectScrollbar>
      </DropdownMenu>
    </ButtonDropdown>
  );
};

export default QuickAddDropdown;
