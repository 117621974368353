import { makeAutoObservable } from "mobx";
import { SiteService } from "../services";
import get from "lodash/get";
import debounce from "lodash/debounce";
import startCase from "lodash/startCase";

export const DUAL_SITES = "DUAL_SITES";
export const WASFATY = "WASFATY";
export const OBLIGATION = "OBLIGATION";

export class RegionSiteModal {
  sites = [];
  isLoading = false;
  search = "";

  isFormType = false;
  type = DUAL_SITES;
  regionId;
  path = "";
  formInfo = {};

  constructor(props) {
    this.type = props?.siteType;
    this.isFormType = props?.isFormType;
    this.path = window.location.pathname.split("/")[1];
    makeAutoObservable(this);
  }

  setPath = (path) => {
    this.path = path;
  };

  getSites = (regionId, clusterId) => {
    // // Find all sites to present in the dropdown menu
    let search = this.search;
    regionId = regionId || this.regionId;

    this.regionId = regionId;

    if (regionId) {
      this.isLoading = true;
      this.sites = [];
      if (clusterId?.length) {
        clusterId = clusterId?.map?.((item) => item._id);
      }
      let param = {
        regionId,
        search,
        type: this.type,
        module: this.path,
        clusterId: clusterId || null,
      };

      if (this.formInfo) {
        param.formInfo = JSON.stringify(this.formInfo);
      }

      SiteService.getSitesByType(param)
        .then((res) => {
          let _sites = get(res, "data.data");
          if (_sites?.length) {
            this.sites = _sites.map((item) => {
              let label = `${item.licenseNumber || item.license || "N/A"}`;
              label = label + (item.name ? ` - ${item.name}` : "");
              label = label + (item.name_ar ? ` - ${item.name_ar}` : "");
              label =
                label + (item.street_name ? ` - ${item.street_name}` : "");

              if (this.isFormType) {
                label = label + `(${startCase(item.form_type)})`;
              }

              return {
                ...item,
                label,
                value: item._id,
              };
            });
          }
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    }
  };

  setFormInfo = (params) => {
    this.formInfo = params;
  };

  onSearch = debounce((search) => {
    this.search = search;
    this.getSites();
  }, 1000);
}
