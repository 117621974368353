import React, { useEffect, useState } from "react";
import Flatpickr from "react-flatpickr";
import { Skeleton } from "primereact/skeleton";
import {
  IntlService,
  ModuleService,
  SiteAuditService,
} from "../../wasfaty/services";
import Graph from "./Graph";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Input,
  InputGroup,
  InputGroupText,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import { SC } from "../../wasfaty/Api/serverCall";
import { Download } from "react-feather";
import { selectThemeColors } from "@utils";
import Select from "react-select";

import hopital from "../../../assets/images/hospital.png";
import { useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import { down, up } from "../../../assets/wasfaty/SVG";

function CardViewWidget({ item }) {
  return (
    <Col md={item?.size ? item.size : "3"}>
      <Card className="widget shadow-none ">
        <div className="header d-flex justify-content-between align-items-center mt-0">
          <div className="name">{IntlService.m(item.name)}</div>
          <div className="value">{item?.ratio?.toFixed(2)} %</div>
        </div>

        {item?.allSites ? (
          <div className="pill-section">
            <div className="d-flex justify-content-center">
              <hr className="m-0 mb-1" style={{ width: "90%" }} />
            </div>
            <div className="d-flex justify-content-between mx-1">
              <div className="pill">
                <p
                  className="standard-obl-pill"
                  style={{ backgroundColor: "#F6F8FA", color: "#525866" }}
                >
                  <div
                    className="standard-obl-pill-dot"
                    style={{ backgroundColor: "#525866" }}
                  />
                  {IntlService.m("Total")}
                </p>
              </div>
              <div className="pill-value">{item?.allSites}</div>
            </div>
            <div className="d-flex justify-content-between m-1">
              <div className="pill">
                <p
                  className="standard-obl-pill"
                  style={{ backgroundColor: "#EFFAF6", color: "#38C793" }}
                >
                  <div
                    className="standard-obl-pill-dot"
                    style={{ backgroundColor: "#38C793" }}
                  />
                  {IntlService.m("Submitted")}
                </p>
              </div>
              <div className="pill-value">{item?.submitted}</div>
            </div>
            <div className="d-flex justify-content-between m-1">
              <div className="pill">
                <p
                  className="standard-obl-pill"
                  style={{ backgroundColor: "#FDEDF0", color: "#DF1C41" }}
                >
                  <div
                    className="standard-obl-pill-dot"
                    style={{ backgroundColor: "#DF1C41" }}
                  />
                  {IntlService.m("Not Submitted")}
                </p>
              </div>
              <div className="pill-value">{item?.notSubmitted}</div>
            </div>
          </div>
        ) : null}
      </Card>
    </Col>
  );
}

function TopWidget({ item }) {
  return (
    <Col md={item?.size ? item.size : "3"}>
      <Card className="widget shadow-none d-flex justify-content-between flex-row align-items-center">
        <div className="header">
          <div className="name">{IntlService.m(item.name)}</div>
          <div className="value">{item?.count}</div>
        </div>

        <div className="mx-1">
          <img src={item.image_url} />
        </div>
      </Card>
    </Col>
  );
}
function SecondWidget({ item }) {
  return (
    <Col md={item?.size ? item.size : "4"}>
      <Card className="widget shadow-none ">
        <div className="header d-flex justify-content-between mb-0 mt-0 align-items-center">
          <div className="name "> {IntlService.m(item.name)}</div>
          <div className="value">{item?.count}</div>
        </div>
      </Card>
    </Col>
  );
}

const DynamicFormGraphs = () => {
  const [form_id, setFormId] = useState("");
  const [form, setForm] = useState("");
  const [data, setdata] = useState(null);
  const [date, setDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  // new Date().toDateString
  const getDashbordData = (date) => {
    setLoading(true);
    SC.getCall({
      url: `submition-dashboard?form_id=${form_id}`,
      params: {
        fromDate: date?.length ? date[0]?.toDateString?.() : null,
        toDate: date?.length ? date[1]?.toDateString?.() : null,
      },
    }).then((res) => {
      setdata(res.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (form_id) {
      setdata(null);
      getDashbordData();
    }
  }, [form_id]);

  const [allForms, setAllForms] = useState([]);

  const getForms = async () => {
    let data = await ModuleService.getDynamicForms();
    let _data = data
      .filter((form) => form.status)
      .map((f) => {
        f.label = f.name;
        f.value = f._id;
        return f;
      });
    setFormId(_data[0]._id);

    setForm(data[0]);
    setAllForms(_data);
  };

  useEffect(() => {
    getForms();
  }, []);

  return (
    <div className="dynamic-form-dashboard">
      <div className="d-flex justify-content-between align-items-center ">
        <div>
          <p className="dash-heading">
            {IntlService.m("Inspection Dashboard")}
          </p>
        </div>
        <div className="d-flex flex-row">
          <div style={{ width: 300, marginRight: 5 }}>
            <Select
              placeholder={IntlService.m("Select Form")}
              value={form}
              onChange={(f) => {
                setForm(f);
                setFormId(f._id);
              }}
              theme={selectThemeColors}
              className="react-select"
              classNamePrefix="select"
              options={allForms}
            />
          </div>
          <InputGroup style={{ width: 300 }}>
            <InputGroupText className="date-picker-side">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.79961 11.5999H9.19961V10.3999H6.79961V11.5999ZM2.59961 4.3999V5.5999H13.3996V4.3999H2.59961ZM4.39961 8.5999H11.5996V7.3999H4.39961V8.5999Z"
                  fill="#0A0D14"
                />
              </svg>
              <span className="mx-1">{IntlService.m("Date")}</span>
            </InputGroupText>

            <Flatpickr
              className="form-control date-picker"
              value={date}
              options={{
                mode: "range",
              }}
              onChange={(e) => {
                if (e.length == 2) {
                  setDate(e);
                  getDashbordData(e);
                }
              }}
              placeholder={IntlService.m("Select date")}
            />
            <figure className="filter-icon">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.29327 15.0999C6.97327 15.0999 6.65993 15.0199 6.3666 14.8599C5.77993 14.5332 5.4266 13.9399 5.4266 13.2732V9.7399C5.4266 9.40657 5.2066 8.90657 4.99993 8.65324L2.5066 6.01324C2.0866 5.59324 1.7666 4.87324 1.7666 4.33324V2.7999C1.7666 1.73324 2.57327 0.899902 3.59993 0.899902H12.3999C13.4133 0.899902 14.2333 1.7199 14.2333 2.73324V4.1999C14.2333 4.8999 13.8133 5.69324 13.4199 6.08657L10.5333 8.6399C10.2533 8.87324 10.0333 9.38657 10.0333 9.7999V12.6666C10.0333 13.2599 9.65993 13.9466 9.19327 14.2266L8.27327 14.8199C7.97327 15.0066 7.63327 15.0999 7.29327 15.0999ZM3.59993 1.8999C3.13327 1.8999 2.7666 2.29324 2.7666 2.7999V4.33324C2.7666 4.5799 2.9666 5.0599 3.21993 5.31324L5.75994 7.98657C6.09994 8.40657 6.43327 9.10657 6.43327 9.73324V13.2666C6.43327 13.6999 6.73327 13.9132 6.85993 13.9799C7.13994 14.1332 7.47993 14.1332 7.73993 13.9732L8.6666 13.3799C8.85327 13.2666 9.03994 12.9066 9.03994 12.6666V9.7999C9.03994 9.08657 9.3866 8.2999 9.8866 7.8799L12.7399 5.35324C12.9666 5.12657 13.2399 4.58657 13.2399 4.19324V2.73324C13.2399 2.27324 12.8666 1.8999 12.4066 1.8999H3.59993Z"
                  fill="#CDD0D5"
                />
                <path
                  d="M3.99995 7.16652C3.90661 7.16652 3.81995 7.13985 3.73328 7.09318C3.49995 6.94652 3.42661 6.63318 3.57328 6.39985L6.85995 1.13318C7.00661 0.89985 7.31328 0.826517 7.54661 0.973183C7.77995 1.11985 7.85328 1.42652 7.70661 1.65985L4.41995 6.92652C4.32661 7.07985 4.16661 7.16652 3.99995 7.16652Z"
                  fill="#CDD0D5"
                />
              </svg>
            </figure>
          </InputGroup>
        </div>
      </div>
      <Row>
        {loading && !data ? (
          <>
            <Row className="mt-2">
              <Col md="4">
                <Skeleton width="" height="4rem"></Skeleton>
              </Col>
              <Col md="4">
                <Skeleton width="" height="4rem"></Skeleton>
              </Col>
              <Col md="4">
                <Skeleton width="" height="4rem"></Skeleton>
              </Col>
            </Row>
            <Row className="mt-2 mb-2">
              <Col md="4">
                <Skeleton width="" height="4rem"></Skeleton>
              </Col>
              <Col md="4">
                <Skeleton width="" height="4rem"></Skeleton>
              </Col>
              <Col md="4">
                <Skeleton width="" height="4rem"></Skeleton>
              </Col>
            </Row>
          </>
        ) : (
          Object.entries(data?.data3 || {})?.map(([title, value]) => (
            <>
              <>
                <p className="section-head mb-2">{IntlService.m(title)}</p>

                {value?.map?.((item) => (
                  <TopWidget item={item} />
                ))}
              </>
            </>
          ))
        )}
      </Row>
      <Row className="mt-2">
        {loading && !data ? (
          <>
            <Col md="6">
              <Skeleton width="" height="4rem"></Skeleton>
            </Col>
            <Col md="6">
              <Skeleton width="" height="4rem"></Skeleton>
            </Col>
          </>
        ) : (
          Object.entries(data?.data || {})?.map(([title, value]) => (
            <>
              <p className="section-head mb-2">{IntlService.m(title)}</p>
              {value.map((item) => (
                <TopWidget item={item} />
              ))}
              {!value?.length && (
                <h6 className="mb-2 px-2">
                  {IntlService.m("No Submissions Information")}
                </h6>
              )}
            </>
          ))
        )}
      </Row>

      <Row>
        {loading && !data ? (
          <>
            <Row className="mt-2">
              <Col md="4">
                <Skeleton width="" height="4rem"></Skeleton>
              </Col>
              <Col md="4">
                <Skeleton width="" height="4rem"></Skeleton>
              </Col>
              <Col md="4">
                <Skeleton width="" height="4rem"></Skeleton>
              </Col>
            </Row>
            <Row className="mt-2 mb-2">
              <Col md="4">
                <Skeleton width="" height="4rem"></Skeleton>
              </Col>
              <Col md="4">
                <Skeleton width="" height="4rem"></Skeleton>
              </Col>
              <Col md="4">
                <Skeleton width="" height="4rem"></Skeleton>
              </Col>
            </Row>
          </>
        ) : (
          Object.entries(data?.data1 || {})?.map(([title, value]) => (
            <>
              <p className="section-head mb-2">{IntlService.m(title)}</p>
              {value.map((item) => (
                <SecondWidget item={item} />
              ))}
              {!value?.length && (
                <h6 className="mb-2 px-2">
                  {IntlService.m("No Submissions Information")}
                </h6>
              )}
            </>
          ))
        )}
      </Row>
      <Row>
        {loading && !data ? (
          <>
            <Row className="mt-2">
              <Col md="3">
                <Skeleton width="" height="10rem"></Skeleton>
              </Col>
              <Col md="3">
                <Skeleton width="" height="10rem"></Skeleton>
              </Col>
              <Col md="3">
                <Skeleton width="" height="10rem"></Skeleton>
              </Col>
              <Col md="3">
                <Skeleton width="" height="10rem"></Skeleton>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col md="3">
                <Skeleton width="" height="10rem"></Skeleton>
              </Col>
              <Col md="3">
                <Skeleton width="" height="10rem"></Skeleton>
              </Col>
              <Col md="3">
                <Skeleton width="" height="10rem"></Skeleton>
              </Col>
              <Col md="3">
                <Skeleton width="" height="10rem"></Skeleton>
              </Col>
            </Row>
            <Row className="mt-2 mb-3">
              <Col md="3">
                <Skeleton width="" height="10rem"></Skeleton>
              </Col>
              <Col md="3">
                <Skeleton width="" height="10rem"></Skeleton>
              </Col>
              <Col md="3">
                <Skeleton width="" height="10rem"></Skeleton>
              </Col>
              <Col md="3">
                <Skeleton width="" height="10rem"></Skeleton>
              </Col>
            </Row>
          </>
        ) : (
          Object.entries(data?.data2 || {})?.map(([title, value]) => (
            <>
              <p className="section-head mb-2">{IntlService.m(title)}</p>
              {value.map((item) => (
                <CardViewWidget item={item} />
              ))}
              {!value?.length && (
                <h6 className="mb-2 px-2">
                  {" "}
                  {IntlService.m("No Submissions Information")}
                </h6>
              )}
            </>
          ))
        )}
      </Row>
      <ComparativeAnalysis date={date} form_id={form_id} form={form} />
      <ComplianceSection date={date} form_id={form_id} form={form} />
    </div>
  );
};

const PdfDownload = ({
  report1,
  report2,
  pdfName = "Comparative Analysis",
  form_slug = "compare_submission",
  form = null,
}) => {
  const [PDF, setPDF] = useState(false);
  const token = JSON.parse(localStorage.getItem("accessToken"));
  const baseUrl = process.env.REACT_APP_BASEURL;

  return (
    <div className="cursorPointer">
      {PDF ? (
        <Spinner className="ml-2" size="sm" style={{ marginLeft: 5 }} />
      ) : (
        <Download
          style={{ cursor: "pointer", marginTop: -10, marginLeft: 5 }}
          size={18}
          onClick={() => {
            setPDF(true);

            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            fetch(process.env.REACT_APP_PDF + "/pdf", {
              headers: myHeaders,

              method: "POST",
              body: JSON.stringify({
                url: `?id=${report1?.form_submition_id}&id_1=${report2?.form_submition_id}&token=${token}&url=${baseUrl}&project=Aman&form_slug=${form_slug}`,
                project: "Aman",
                form: {
                  name: form?.name,
                },
              }),
            })
              .then((response) => {
                return response.blob();
              })
              .then((res) => {
                setPDF(false);
                try {
                  const link = document.createElement("a");
                  link.href = URL.createObjectURL(res);
                  link.download = pdfName;
                  link.click();
                  setPDF(false);
                } catch (error) {
                  setPDF(false);
                  // toast.error(IntlService.m("There is a problem with your network connection!"));
                }
              });
          }}
        />
      )}
    </div>
  );
};

const ComparativeAnalysis = ({ date, form_id, form }) => {
  const [data, setdata] = useState([]);

  const [loading, setLoading] = useState(false);

  const getSiteData = (date) => {
    setLoading(true);
    SC.getCall({
      url: `submission-dashboard-latest?form_id=${form_id}`,
      params: {
        fromDate: date?.length ? date[0]?.toDateString?.() : null,
        toDate: date?.length ? date[1]?.toDateString?.() : null,
      },
    }).then((res) => {
      setdata(res.data.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    getSiteData(date);
  }, [date, form_id]);

  const columns = [
    {
      width: "120px",
      name: IntlService.m("Region"),
      selector: (row) => IntlService.m(row.region_name),
    },
    {
      name: IntlService.m("Site"),
      selector: (row) => row.site_name,
    },
    {
      name: IntlService.m("Report 1"),
      selector: ({ submissions }) => {
        let row = submissions[1];

        if (!row) return "N/A";
        return (
          <span style={{ display: "flex" }}>
            {row.ratio.toFixed(2) + " %"}{" "}
            <PdfDownload
              report1={row}
              pdfName="Report 1"
              form_slug={null}
              form={form}
            />
          </span>
        );
      },
    },

    {
      name: IntlService.m("Report 2"),
      selector: ({ submissions }) => {
        let row = submissions[0];

        if (!row) return "N/A";
        return (
          <span style={{ display: "flex" }}>
            {row.ratio.toFixed(2) + " %"}{" "}
            <PdfDownload
              report1={row}
              pdfName="Report 2"
              form_slug={null}
              form={form}
            />
          </span>
        );
      },
    },

    {
      name: IntlService.m("Comparative Analysis PDF"),
      selector: ({ submissions }) => {
        if (submissions?.length > 1) {
          return (
            <PdfDownload
              report1={submissions[1]}
              report2={submissions[0]}
              form={form}
            />
          );
        }

        return "-";
      },
    },
  ];

  return (
    <>
      <Card>
        <CardHeader>
          <p className=" section-head m-0">
            {IntlService.m("Comparative Analysis")}
          </p>

          <div className="d-flex justify-content-between pill-section gap-1">
            <div className="pill">
              <p
                className="standard-obl-pill standard-pill-table"
                style={{ backgroundColor: "#CBF5E5", color: "#176448" }}
              >
                <div
                  className="standard-obl-pill-dot"
                  style={{ backgroundColor: "#176448" }}
                />
                {">90%"}
              </p>
            </div>

            <div className="pill">
              <p
                className="standard-obl-pill standard-pill-table"
                style={{ backgroundColor: "#FBDFB1", color: "#693D11" }}
              >
                <div
                  className="standard-obl-pill-dot"
                  style={{ backgroundColor: "#693D11" }}
                />
                {"85%-90%"}
              </p>
            </div>

            <div className="pill">
              <p
                className="standard-obl-pill standard-pill-table"
                style={{ backgroundColor: "#FFDAC2", color: "#6E330C" }}
              >
                <div
                  className="standard-obl-pill-dot"
                  style={{ backgroundColor: "#6E330C" }}
                />
                {"80%-85%"}
              </p>
            </div>

            <div className="pill">
              <p
                className="standard-obl-pill standard-pill-table"
                style={{ backgroundColor: "#F8C9D2", color: "#710E21" }}
              >
                <div
                  className="standard-obl-pill-dot"
                  style={{ backgroundColor: "#710E21" }}
                />
                {"<80%"}
              </p>
            </div>
          </div>
        </CardHeader>

        <CardBody>
          {loading ? (
            <>
              <Row className="mt-2">
                <Col md="3">
                  <Skeleton width="" height="2rem"></Skeleton>
                </Col>
                <Col md="3">
                  <Skeleton width="" height="2rem"></Skeleton>
                </Col>
                <Col md="3">
                  <Skeleton width="" height="2rem"></Skeleton>
                </Col>
                <Col md="3">
                  <Skeleton width="" height="2rem"></Skeleton>
                </Col>
              </Row>

              <Row className="mt-2">
                <Col md="3">
                  <Skeleton width="" height="2rem"></Skeleton>
                </Col>
                <Col md="3">
                  <Skeleton width="" height="2rem"></Skeleton>
                </Col>
                <Col md="3">
                  <Skeleton width="" height="2rem"></Skeleton>
                </Col>
                <Col md="3">
                  <Skeleton width="" height="2rem"></Skeleton>
                </Col>
              </Row>
            </>
          ) : (
            <DataTable
              columns={columns}
              data={data}
              className="graph-page-data"
              striped
              pagination
              noDataComponent={
                loading ? (
                  <div className="my-3">
                    <Spinner />
                  </div>
                ) : (
                  IntlService.m("No Data!")
                )
              }
            />
          )}
        </CardBody>
      </Card>
    </>
  );
};

const ComplianceSection = ({ date, form_id }) => {
  const [data, setdata] = useState([]);

  const [loading, setLoading] = useState(false);

  const getSiteData = (date) => {
    setLoading(true);
    SC.getCall({
      url: `submission-dashboard-bysite?form_id=${form_id}`,
      params: {
        fromDate: date?.length ? date[0]?.toDateString?.() : null,
        toDate: date?.length ? date[1]?.toDateString?.() : null,
      },
    }).then((res) => {
      setdata(res.data.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    getSiteData(date);
  }, [date, form_id]);

  const columns = [
    {
      width: "120px",
      name: IntlService.m("Region"),
      selector: (row) => IntlService.m(row.region_name),
    },
    {
      name: IntlService.m("Site"),
      selector: (row) => row.name,
    },
    {
      name: IntlService.m("Self Assessment"),
      selector: (row) => row.selfComplyPercenatge.toFixed(2) + " %",
    },
    {
      name: IntlService.m("Inspection"),
      selector: (row) => (
        <span>
          {row.inspectionComplyPercantage.toFixed(2) + " %"}{" "}
          <span>
            {row.inspectionComplyPercantage >= row.selfComplyPercenatge
              ? up
              : down}
          </span>{" "}
        </span>
      ),
    },
  ];

  return (
    <>
      <Card>
        <CardHeader>
          <p className=" section-head m-0">{IntlService.m("Compliance")}</p>

          <div className="d-flex justify-content-between pill-section gap-1">
            <div className="pill">
              <p
                className="standard-obl-pill standard-pill-table"
                style={{ backgroundColor: "#CBF5E5", color: "#176448" }}
              >
                <div
                  className="standard-obl-pill-dot"
                  style={{ backgroundColor: "#176448" }}
                />
                {">90%"}
              </p>
            </div>

            <div className="pill">
              <p
                className="standard-obl-pill standard-pill-table"
                style={{ backgroundColor: "#FBDFB1", color: "#693D11" }}
              >
                <div
                  className="standard-obl-pill-dot"
                  style={{ backgroundColor: "#693D11" }}
                />
                {"85%-90%"}
              </p>
            </div>

            <div className="pill">
              <p
                className="standard-obl-pill standard-pill-table"
                style={{ backgroundColor: "#FFDAC2", color: "#6E330C" }}
              >
                <div
                  className="standard-obl-pill-dot"
                  style={{ backgroundColor: "#6E330C" }}
                />
                {"80%-85%"}
              </p>
            </div>

            <div className="pill">
              <p
                className="standard-obl-pill standard-pill-table"
                style={{ backgroundColor: "#F8C9D2", color: "#710E21" }}
              >
                <div
                  className="standard-obl-pill-dot"
                  style={{ backgroundColor: "#710E21" }}
                />
                {"<80%"}
              </p>
            </div>
          </div>
        </CardHeader>

        <CardBody>
          {loading ? (
            <>
              <Row className="mt-2">
                <Col md="3">
                  <Skeleton width="" height="2rem"></Skeleton>
                </Col>
                <Col md="3">
                  <Skeleton width="" height="2rem"></Skeleton>
                </Col>
                <Col md="3">
                  <Skeleton width="" height="2rem"></Skeleton>
                </Col>
                <Col md="3">
                  <Skeleton width="" height="2rem"></Skeleton>
                </Col>
              </Row>

              <Row className="mt-2">
                <Col md="3">
                  <Skeleton width="" height="2rem"></Skeleton>
                </Col>
                <Col md="3">
                  <Skeleton width="" height="2rem"></Skeleton>
                </Col>
                <Col md="3">
                  <Skeleton width="" height="2rem"></Skeleton>
                </Col>
                <Col md="3">
                  <Skeleton width="" height="2rem"></Skeleton>
                </Col>
              </Row>
            </>
          ) : (
            <DataTable
              columns={columns}
              data={data}
              className="graph-page-data"
              striped
              pagination
              noDataComponent={
                loading ? (
                  <div className="my-3">
                    <Spinner />
                  </div>
                ) : (
                  IntlService.m("No Data!")
                )
              }
            />
          )}
        </CardBody>
      </Card>
    </>
  );
};

export default DynamicFormGraphs;
