import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Progress,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Collapse,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Spinner,
} from "reactstrap";
import {
  acceptSvg,
  deletesvg,
  infoSvg,
  moreVerticle,
  rejectSvg,
  timeSvgbig,
} from "./SVGSheduleAsset";
import startCase from "lodash/startCase";
import moment from "moment";
import { Edit } from "react-feather";
import { BsClockHistory } from "react-icons/bs";
import Arrowdown from "../../../../../assets/wasfaty/Arrowdown.svg";
import {
  IntlService,
  ModuleService,
  NavigationService,
  ScheduleTaskService,
  TaskService,
} from "../../../../wasfaty/services";
import ButtonGreenOutline from "../../../../components/buttons/ButtonGreenOutline";
import HistoryModal from "./HistoryModal";
import ReAssignTaskModal from "./ReAssignTaskModal";
import { observer } from "mobx-react";
import { FormattedMessage } from "react-intl";
import toast from "react-hot-toast";
import { ScheduleTaskModal } from "../../../../wasfaty/Models/ScheduleTaskModal";
import CalanderDropDown from "./CalanderDropDown";
import TaskModal from "../../../../wasfaty/Models/TaskModal";
import get from "lodash/get";
import { Tooltip } from "antd";

let forms = {
  "test module": "test",
  "Schedule Task Form": "Schedule-Task",
  "Medical Mission Hajj Form": "Medical-Mission",
  Pharmacies: "Pharmacies-Audit",
  "PHC Hajj Form": "Hajj-PHC",
  "General Hospital Hajj Form": "Hajj-General-Hospital",
  "General Complex Hajj Form": "Hajj-General-Complex",
  "Private Hospital Hajj Form": "Hajj-Private-Hospital",
  "Private Health Institutions on Narcotic Drugs Form": "Narcotic-Drugs",
  "Lab Audit Form": "Lab-Audit",
  "Dental Lab Form": "Dental-Lab",
  "Phc Night Shift Form": "Phc-Night-Shift",
  "Phc Form": "Phc-Audit",
  "General Medical Complex Form": "General-Medical-Complex",
  "General Hospital": "General-Hospital",
  Regions: "Regions",
  "Private Hospital": "Private-Hospital",
  "Specialized Medical Complex Form": "Specialized-Medical",
  "Pharmacy visit Report": "Regular-Visit",
  "Wasfaty Pharmacies and RASSD Form": "Wasfaty-Audit",
  "Pharmacies Self Assessment - Medicine Availability": "Self-Assessment",
  "Pharmacies Medicine Availability Form": "Medicine-Availability",
  Sites: "Sites",
  Users: "Users",
};
/**
 * @param {Object} props
 * @param {TaskModal} props.taskSelected
 */
const RejectModel = observer(
  ({
    rejectModel,
    toggleRejectModel,
    inputValue,
    onChange,
    taskSelected,
    SubmitAction,
  }) => {
    
    return (
      <Modal isOpen={rejectModel} toggle={toggleRejectModel} centered>
        <ModalHeader>{IntlService.m("Reject Reason")}</ModalHeader>
        <ModalBody className="d-flex justify-content-center">
          <Input type="textarea" value={inputValue} onChange={onChange} />
          {/* )} */}
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              if (inputValue != "") {
                SubmitAction("rejected", true, taskSelected);
                toggleRejectModel();
              } else {
                toast.error(IntlService.m("Please fill the Reject Reason!"));
              }
            }}
          >
            {IntlService.m("Submit")}
          </Button>
          <Button color="secondary" onClick={toggleRejectModel}>
            {IntlService.m("Cancel")}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
);

/**
 * @param {Object} props
 * @param {ScheduleTaskModal} props.schedule
 */
function ScheduleCard({ tab, schedule, DeleteData }) {
  const [collapse, setCollapse] = useState(false);
  const [modal, setModal] = useState(false);
  const [userModal, setUserModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [taskSelected, setTaskSelected] = useState([]);
  const [taskToReassign, setTaskToReassign] = useState(null);
  const [inputValue, setInputValue] = useState("");

  const [rejectModel, setRejectModel] = useState(false);
  const [acceptModel, setAcceptModel] = useState(false);
  const [actionTask, setActionTask] = useState(null);
  const [isDueDateUpdateing, setIsDueDateUpdating] = useState(false);
  const [is24Hours, setIs24Hours] = useState(false);

  const createdAt = schedule?.created_at;

  useEffect(() => {
    if (!createdAt) return;

    const checkIf24HoursPassed = () => {
      const createdDate = new Date(createdAt);
      const now = new Date();
      const diffInMs = now - createdDate;
      const diffInHours = diffInMs / (1000 * 60 * 60);

      setIs24Hours(diffInHours >= 24);
    };

    checkIf24HoursPassed();

    const intervalId = setInterval(checkIf24HoursPassed, 6000);

    return () => clearInterval(intervalId);
  }, [createdAt]);

  let percentage = (
    (schedule.tasks.filter((task) => task.is_approved).length /
      schedule.tasks.length) *
    100
  ).toFixed(2);

  const toggleCollapse = () => {
    setCollapse((prev) => !prev);
  };
  const toggleRejectModel = () => {
    setRejectModel((prev) => !prev);
  };
  const toggleAcceptModel = () => {
    setAcceptModel((prev) => !prev);
  };
  function handleInputChange(event) {
    setInputValue(event.target.value);
  }

  const onDateChange = (due_date) => {
    setIsDueDateUpdating(true);
    ScheduleTaskService.updateDueDate(schedule._id, due_date).then((res) => {
      setIsDueDateUpdating(false);
      if (res.status == 200) {
        toast.success(res?.data?.data);
        schedule.init(res?.data?.schedule);
      } else {
        toast.error(res.response.data.data);
      }
    });
  };

  /**
   * @param {string} status
   * @param {string} reject
   * @param {TaskModal} task
   */
  const SubmitAction = (status, reject, task) => {
    TaskService.updateStatus(status, task._id, reject ? inputValue : null).then(
      (res) => {
        if (res.status == 200) {
          toast.success(res?.data?.data);
          schedule.init(res?.data?.schedule);
          task.init(res.data.task);
          // NavigationService.Navigation("/Schedule-Task");
        } else {
          toast.error(res.response.data.data);
        }
        setInputValue("");
        // setLoading(false);
      }
    );
  };

  /**
   * @param {object} props
   * @param {TaskModal} taskSelected
   */
  const AcceptModel = observer(({ taskSelected }) => {
    return (
      <Modal isOpen={acceptModel} toggle={toggleAcceptModel} centered>
        <ModalBody className="d-flex justify-content-center">
          <h3>
            {IntlService.m(
              "Are you sure want to accept without reviewing the submission!"
            )}
          </h3>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              SubmitAction("approved", false, taskSelected);
              toggleAcceptModel();
            }}
          >
            {IntlService.m("Yes")}
          </Button>
          <Button color="secondary" onClick={toggleAcceptModel}>
            {IntlService.m("No")}
          </Button>
        </ModalFooter>
      </Modal>
    );
  });

  /**
   * @param {object} props
   * @param {TaskModal} props.task
   */
  const RenderTask = observer(({ task }) => {
    return (
      <div className="content row">
        <div className="col-md-2 col-sm-3">
          <div className="mb-2">
            <p className="label d-flex align-items-start flex-column m-0">
              <div>
                <UncontrolledTooltip placement="top" target="history">
                  {IntlService.m("History")}
                </UncontrolledTooltip>
                <BsClockHistory
                  onClick={() => {
                    setTaskSelected(task.history);
                    toggle();
                  }}
                  size={15}
                  style={{
                    marginRight: IntlService.isRtl ? 0 : 5,
                    marginLeft: IntlService.isRtl ? 5 : 0,
                    cursor: "pointer",
                  }}
                  id="history"
                />
                <i
                  style={{
                    color: "#b2b2b2",
                    fontSize: 11,
                    fontWeight: 200,
                    marginBottom: 5,
                  }}
                >
                  {task?.reportNumber}
                </i>
                {/* {IntlService.m("Task Details")} */}
              </div>
            </p>

            <p className="user-namemb-2">{task.user.name}</p>

            {task.canReassign && (
              <ButtonGreenOutline
                className={"mt-2 mb-2"}
                title="Reassign"
                onClick={() => {
                  setTaskToReassign(task);
                  setUserModal(true);
                }}
              />
            )}
          </div>
        </div>
        <div className="col-md-3 col-sm-9">
          {/* <p className="label">{IntlService.m("Assigned Steps")}</p> */}
          <div className="steps">
            {task.steps.map((step, index) => (
              <p className="step" key={index}>
                {IntlService.m(step.label)}
              </p>
            ))}
          </div>
        </div>
        <div className="col-md-2 col-sm-3">
          {/* <p className="label">{IntlService.m("Status")}</p> */}

          <span>
            <span
              className={`badge ${task.status || "text-black"} `}
              style={{ fontWeight: 600 }}
            >
              {IntlService.m(startCase(task.status) || "N/A")}
            </span>
          </span>
        </div>
        <div className="col-md-3 col-sm-3">
          {/* <p className="label">{IntlService.m("Rejected Reason")}</p> */}
          <p className="date">
            {task.is_rejected || task.is_cancelled
              ? get(
                  task?.history?.filter(
                    (his) =>
                      his.is_rejected || (his.is_cancelled && !!his.reason)
                  ),
                  "0"
                )?.reason
              : IntlService.m("N/A")}
          </p>
        </div>
        {/* {task.data && ( */}
        <div className="col-md-2 col-sm-12 actions">
          {!!task.data && (
            <>
              <figure
                id="details"
                onClick={() => {
                  let slug = task.form_name;

                  if (task.meta_data) {
                    slug = forms[task.meta_data.name];
                  }

                  if (forms[slug]) {
                    slug = forms[slug];
                  }

                  if (task.meta_data?.isDynamicForm) {
                    NavigationService.Navigation(
                      `/${task.meta_data.form_id}/Form-Builder/${task._id}/task`
                    );
                  } else {
                    NavigationService.Navigation(`/${slug}/Task/${task._id}`);
                  }
                }}
              >
                {infoSvg}
              </figure>
              <UncontrolledTooltip placement="top" target="details">
                <FormattedMessage id="Details" defaultMessage="Details" />
              </UncontrolledTooltip>
            </>
          )}
          {task.is_submitted && (
            <>
              <figure
                onClick={() => {
                  setActionTask(task);
                  toggleAcceptModel();
                }}
                id="Accept"
              >
                {acceptSvg}
              </figure>
              <UncontrolledTooltip placement="top" target="Accept">
                <FormattedMessage id="Accept" defaultMessage="Accept" />
              </UncontrolledTooltip>
              <figure
                id="Reject"
                onClick={() => {
                  setActionTask(task);
                  toggleRejectModel();
                }}
              >
                {rejectSvg}
              </figure>
              <UncontrolledTooltip placement="top" target="Reject">
                <FormattedMessage id="Reject" defaultMessage="Reject" />
              </UncontrolledTooltip>
            </>
          )}
        </div>
      </div>
    );
  });

  const TaskHeader = observer(() => {
    return (
      <div
        className="content row"
        style={{
          background: "#EBEBEB",
          alignItems: "center",
          borderRadius: 5,
        }}
      >
        <div className="col-md-2 col-sm-3">
          <div>
            <p className="label d-flex align-items-start flex-column m-0">
              <div>{IntlService.m("Task Details")}</div>
            </p>
          </div>
        </div>
        <div className="col-md-3 col-sm-9">
          <div>
            <p className="label d-flex align-items-start flex-column m-0">
              <div>{IntlService.m("Assigned Steps")}</div>
            </p>
          </div>
        </div>
        <div className="col-md-2 col-sm-3">
          <div>
            <p className="label d-flex align-items-start flex-column m-0">
              <div>{IntlService.m("Status")}</div>
            </p>
          </div>
        </div>
        <div className="col-md-3 col-sm-3">
          <div>
            <p className="label d-flex align-items-start flex-column m-0">
              <div>{IntlService.m("Reason")}</div>
            </p>
          </div>
        </div>
        <div className="col-md-2 col-sm-12 actions">
          <div>
            <p className="label d-flex align-items-start flex-column m-0">
              <div>{IntlService.m("Actions")}</div>
            </p>
          </div>
        </div>
      </div>
    );
  });

  return (
    <Card className="card-margin">
      <HistoryModal modal={modal} toggle={toggle} taskSelected={taskSelected} />
      <RejectModel
        taskSelected={actionTask}
        onChange={handleInputChange}
        inputValue={inputValue}
        toggleRejectModel={toggleRejectModel}
        rejectModel={rejectModel}
        SubmitAction={SubmitAction}
      />
      <AcceptModel taskSelected={actionTask} />
      {!!userModal && taskToReassign && (
        <ReAssignTaskModal
          modal={userModal}
          task={taskToReassign}
          toggle={() => {
            setUserModal((o) => !o);
            setTaskToReassign(null);
          }}
          close={() => {
            setTaskToReassign(null);
            setUserModal(false);
          }}
        />
      )}
      <CardBody className="schedule">
        <Row className="head">
          {schedule.canDelete && tab === "active" && (
            <Col md="1" sm="2" xs="2">
              {!is24Hours ? (
                <UncontrolledDropdown direction="end">
                  <DropdownToggle color="white" className="m-0 p-0">
                    {moreVerticle}
                  </DropdownToggle>

                  <DropdownMenu>
                    {/* <DropdownItem
                  onClick={() =>
                    NavigationService.Navigation(
                      `/Schedule-Task/Edit/${schedule._id}`
                    )
                  }
                  className="w-100"
                >
                  <div className="d-flex justify-content-start align-items-center">
                    <Edit size={"10"} /> <p className="m-0 marginleft">Edit</p>
                  </div>
                </DropdownItem> */}
                    <DropdownItem
                      onClick={() => DeleteData(schedule._id)}
                      className="w-100"
                    >
                      <div className="d-flex justify-content-start align-items-center">
                        {deletesvg}{" "}
                        <p className="m-0 marginleft">
                          {IntlService.m("Delete")}
                        </p>
                      </div>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              ) : null}
            </Col>
          )}
          <Col
            md="4 p-0 "
            sm="8"
            xs="8"
            className="d-flex justify-content-start align-items-center"
          >
            <UncontrolledTooltip placement="top" target="history-schdule">
              {IntlService.m("History")}
            </UncontrolledTooltip>
            <div>
              <BsClockHistory
                onClick={() => {
                  setTaskSelected(schedule.scheduleHistory);
                  toggle();
                }}
                size={15}
                style={{
                  cursor: "pointer",
                  marginRight: IntlService.isRtl ? 0 : 5,
                  marginLeft: IntlService.isRtl ? 5 : 0,
                }}
                id="history-schdule"
              />
            </div>
            <div>
              <p
                className="title"
                style={{ width: 150, marginLeft: IntlService.isRtl ? 5 : 0 }}
              >
                {IntlService.m(
                  ModuleService.getNameBySlug(schedule.form_name.label) ||
                    schedule?.meta_data?.name ||
                    "-"
                )}
              </p>
            </div>
            <div>
              <p className="title">{IntlService.m(schedule.region.name)}</p>
              <p className="title">
                {schedule?.site?.name_ar || schedule?.site?.name} ({" "}
                {schedule?.site?.licenseNumber})
              </p>
            </div>
          </Col>
          {tab === "active" && (
            <Col className="bar " md="2">
              <div className="w-100">
                <div className="d-flex justify-content-between align-items-center">
                  <p className="label">{schedule?.user?.name}</p>
                  <p className="percent">{percentage} %</p>
                </div>
                <Progress
                  value={percentage}
                  color={schedule.is_completed ? "success" : "danger"}
                  className="progress"
                  style={{
                    backgroundColor: "#F5ABAB",
                    height: "8px",
                  }}
                />
              </div>
              <div className="task">
                <p className="title">{IntlService.m("Task")}</p>
                <p className="label">
                  {schedule.tasks.length < 10
                    ? `0${schedule.tasks.length}`
                    : `${schedule.tasks.length}`}
                </p>
              </div>
            </Col>
          )}
          <Col className="time-info" md="2" sm="6">
            <p className="">{IntlService.m("Due Date")}</p>
            <div className="d-flex justify-content-between align-items-center">
              {isDueDateUpdateing ? (
                <Spinner size={"sm"} />
              ) : (
                <>
                  <figure>{timeSvgbig}</figure>
                  <CalanderDropDown
                    date={schedule.due_date}
                    onDateChange={onDateChange}
                    schedule={schedule}
                    tab={tab}
                  />
                </>
              )}
            </div>
          </Col>
          {tab === "deleted" && (
            <>
              <Col className="deleted-info" md="2" sm="6">
                {schedule.delete_reason && (
                  <>
                    <p className="">{IntlService.m("Deleted Reason")}</p>

                    <Tooltip
                      className="inner-div-first"
                      placement="top"
                      title={schedule?.delete_reason}
                    >
                      {schedule.delete_reason}
                    </Tooltip>
                  </>
                )}
              </Col>
              <Col
                md="2 p-0 "
                sm="8"
                xs="8"
                className="justify-content-start align-items-center deleted-info"
              >
                {schedule.responsiable?.name && (
                  <>
                    <p className="">{IntlService.m("Deleted By")}</p>
                    <div className="inner-div-second">
                      <Tooltip
                        placement="top"
                        title={schedule?.responsiable?.email}
                      >
                        {schedule?.responsiable?.name}
                      </Tooltip>
                    </div>
                  </>
                )}
              </Col>
            </>
          )}
          <Col
            md={tab === "active" ? (schedule.canDelete ? "3" : "4") : "2"}
            sm="6"
            className="d-flex justify-content-between status-badge"
          >
            <div
              className={`status-badge-${
                schedule.is_completed ? "completed" : "notcompleted"
              }`}
            >
              <p>
                {IntlService.m(
                  schedule.is_completed ? "Completed" : "Not Completed"
                )}
              </p>
            </div>
            {tab === "active" && (
              <div className="d-flex align-items-center infoSvg">
                {schedule.is_completed && (
                  <>
                    <UncontrolledTooltip placement="top" target="alldetails">
                      <FormattedMessage id="Details" defaultMessage="Details" />
                    </UncontrolledTooltip>
                    <figure
                      id="alldetails"
                      className="mx-1"
                      onClick={() => {
                        if (schedule.meta_data?.isDynamicForm) {
                          NavigationService.Navigation(
                            `/${schedule.meta_data.form_id}/Form-Builder/${schedule?.submission_id}`
                          );
                        } else {
                          NavigationService.Navigation(
                            `/${schedule?.form_name?.label}/Details/${schedule?.submission_id}`
                          );
                        }
                      }}
                    >
                      {infoSvg}
                    </figure>
                  </>
                )}
                <img
                  src={Arrowdown}
                  onClick={toggleCollapse}
                  className={`dropdown-toggle ${collapse && "active"}`}
                />
              </div>
            )}
          </Col>
          <Col md="12">
            <Collapse isOpen={collapse}>
              <TaskHeader />
              {schedule.tasks.map((task, index) => (
                <RenderTask task={task} key={index} />
              ))}
            </Collapse>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default observer(ScheduleCard);
