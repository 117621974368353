import React, { forwardRef, useState } from "react";
import { Fragment } from "react-is";
import PaginationComponent from "./Pagination";
import { observer } from "mobx-react";
import { useIntl } from "react-intl";
import { Dropdown, Menu } from "antd";
import { paginationPagesOptions } from "../../../../utility/constants";
import { ChevronDownIcon } from "../../../../assets/wasfaty/SVG";

function PaginationFooter({
  handlePageChange,
  pagination = {},
  isStopPaginationFirstCall,
}) {
  const intl = useIntl();
  const message = (msg) => {
    return intl.formatMessage({
      id: msg,
      defaultMessage: msg,
    })
  }

  const [rows, setRows] = useState(pagination?.perPage || 10)

  const handleDropdownChange = (item) => {
    setRows(item.key)
    const paginationObj = pagination;
    paginationObj.perPage = Number(item.key);
    handlePageChange(paginationObj);
  }

  const menu = (
    <Menu>
      {paginationPagesOptions.map((item, index) => (
          <Menu.Item key={index + 1} onClick={()=> handleDropdownChange(item)}>
            {item.value}
          </Menu.Item>
        ))}
    </Menu>
  );

  return (
    <Fragment>
      <div className="d-flex justify-content-between align-items-center pagination_footer">
        <div className="mt-1">
          <p className="list-info">{`${message("Showing")} ${pagination?.to || 0
            } ${message("from")} ${pagination?.totalPages} ${message(
              "data"
            )}`}</p>
        </div>
        <div className="">
          <PaginationComponent
            pagination={pagination}
            handlePageChange={handlePageChange}
            isStopPaginationFirstCall={isStopPaginationFirstCall}
          />
        </div>
        <div className="">
          <Dropdown trigger={['click']} overlay={menu}> 
            <div className="dropdown" >
              <div className='chevron'>{rows} / Page&nbsp;{ChevronDownIcon}</div>
            </div>
          </Dropdown>
        </div>
      </div>
    </Fragment>
  );
}

export default observer(PaginationFooter);
