import React from "react";
import { Button, Card, Col, Row } from "reactstrap";

import {
  getTextColor,
  updateBackground,
  updateLinesColor,
} from "../RegionalDashboardHelper";
import { ArrowTopRightIcon } from "../../../../../assets/wasfaty/SVG";
import CustomeScrollbar from "../../../../../@core/components/CustomeScrollbar";
import { IntlService, ModuleService } from "../../../../wasfaty/services";

const RegionalComplianceCard = ({
  isReset,
  setIsReset,
  setFormId,
  submissions,
  sectionName,
}) => {
  return (
    <Card className="region_compliance_card">
      <div className="d-flex justify-content-between align-items-center">
        <div className="region_name">{IntlService.m(sectionName)}</div>
        <div className="d-flex justify-content-between align-items-center">
          {isReset && (
            <div
              onClick={() => {
                setFormId(null);
                setIsReset(false);
              }}
              className="reset_button"
            >
              {IntlService.m('Clear')}
            </div>
          )}
          {ArrowTopRightIcon}
        </div>
      </div>
      <div className="hr_row" />
      {!!submissions?.length ? (
        <CustomeScrollbar height={335}>
          <Row className="gap-1" style={{ marginRight: 0 }}>
            {submissions?.map((item, index) => {
              const percentage = 100 - item.percentage;
              return (
                <Col xxl={12} key={index}>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="sites_name">{ModuleService.getNameId(item.form_id)}</div>
                    <div
                      onClick={() => {
                        setIsReset(true);
                        setFormId(item.form_id);
                      }}
                      className="progress_bar_container"
                    >
                      <div
                        className="percentage"
                        style={{ color: getTextColor(item.percentage) }}
                      >
                        <div className="p_percentage">{item.percentage}%</div>
                        <div className="p_text">{IntlService.m('Complied')}</div>
                      </div>
                      <progress
                        value={percentage}
                        max="100"
                        style={{
                          "--background-color": updateBackground(
                            item.percentage
                          ),
                          "--lines-color": updateLinesColor(item.percentage),
                        }}
                      ></progress>
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
        </CustomeScrollbar>
      ) : (
        <div style={{ textAlign: "center" }} className="no_data_found">
          {IntlService.m('No data found')}
        </div>
      )}
    </Card>
  );
};

export default RegionalComplianceCard;
