import { Resources } from "../Resource";
import { SC } from "../Api/serverCall";
import { getRoleLabel } from "./AuthService";

class Service extends Resources {
  route = "Users";
  allUsers = "allUsers";
  isDotNet = true
  routes={
    find:"/user-index", 
    show:"/GetUserDetail",
    create:"/Create",
    update:"/Update",
    delete:"/Delete",
    details:"/GetUserDetail",
    export:"Users/export"
  }

  constructor() {
    super(arguments);
  }

  getHeaders() {
    return [
      { title: "Name", dataIndex: "name" },
      { title: "Email", dataIndex: "email" },
      { title: "Role", dataIndex: "type" },
      { title: "Region", dataIndex: "region" },
      { title: "Cluster", dataIndex: "cluster" },
      { title: "Facilities", dataIndex: "facilities" },
    ];
  }

  getUsers(params) {
    return SC.getCall({
      url: this.allUsers,
      params,
    });
  }

  mapExportData(data) {
    data = data.map((item) => {
      item.region = item?.region?.length
        ? item.region.map(({ name }) => name).join(", ")
        : "";

      item.type = getRoleLabel(item.type);

      if (item?.clusters?.length) {
        item.cluster = item.clusters.map(({ name }) => name).join(", ");
      }

      if (item?.facilities?.length) {
        item.facilities = item.facilities
          .map(({ name, name_ar }) => name_ar || name)
          .join(", ");
      }

      return item;
    });

    return {
      headers: this.getHeaders(),
      data,
    };
  }
}

const UserService = new Service();
export default UserService;
